import React, { useEffect } from "react";
import { useLocation } from "../../context/LocationContext";
import { useImageViewer } from "../../context/ImageViewerContext";

const WellLocationData = () => {
  const {
    well,
    locationData,
    setSelectedFieldReference,
    setSelectedFieldData,
    setIsSlideOverOpen,
    setSelectedReference,
    setIsModalOpen,
    setTempFieldValue,
    updateWellLocation,
    coordinates
  } = useLocation();

  const { setIsSlideOverOpen: openImageViewer, tempFieldValue,tempReference, newData } = useImageViewer();

  const handleFieldClick = (field, reference) => {
    setSelectedFieldReference(field);
    setSelectedFieldData(locationData[field]);
    setSelectedReference(reference);
    openImageViewer(true);
  };

  const handleOptionClick = (key) => {
    setTempFieldValue(key);
    setIsModalOpen(true);
  };

  useEffect(()=>{
    handleSave(tempFieldValue,tempReference);
  },[newData])
  
  const handleSave = (newValue, newReference) => {
    setTempFieldValue(newValue);
    setSelectedReference(newReference);
    updateWellLocation({
      newValue,
      newReference
    });
    setIsModalOpen(true);
  };

  const getFieldName = (field="") => {
    return field
            .replace(/_/g, ' ')
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
            .join(' '); 
  }
  return (
    <div>
        {(coordinates && coordinates.length > 1) ? (
          <a
            href={`/offset-analysis?well_id=${well.well_id}&lat=${coordinates[1]}&long=${coordinates[0]}`}
            style={{
              color: '#4338CA', // Link color
              textDecoration: 'none', // Removes underline
              // padding: '10px 15px', // Adds padding around the link
              border: '1px solid #4338CA', // Adds a border
              borderRadius: '4px', // Rounded corners
              transition: 'background-color 0.3s, color 0.3s', // Smooth transition for hover
            }}
            onMouseEnter={(e) => {
              e.target.style.backgroundColor = '#4338CA'; // Change background on hover
              e.target.style.color = 'white'; // Change text color on hover
            }}
            onMouseLeave={(e) => {
              e.target.style.backgroundColor = 'transparent'; // Reset background
              e.target.style.color = '#4338CA'; // Reset text color
            }}
          >
            Offset Analysis
          </a>
        ) : (
          "Update Ns Latitude and EW Longitude Fields for enabling offset Analysis."
        )}
      <table className="min-w-full bg-white">
        <thead>
          <tr>
            <th className="py-2 px-4 border">Field</th>
            <th className="py-2 px-4 border">Value</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(locationData || {})?.map(([field, data]) => (
            <tr key={field} onClick={() => handleFieldClick(field, data?.display?.reference)}>
              <td className="py-2 px-4 border cursor-pointer text-black">{getFieldName(field)}</td>
              <td className={`py-2 px-4 border cursor-pointer ${data?.history?.length>0 ? "text-green-500" : "text-blue-500"}`}>{data?.display?.value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default WellLocationData;
