import React, { useEffect, useState, useRef } from "react";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { useLocation } from "../context/LocationContext";
import { ChevronLeftIcon } from "@heroicons/react/24/solid";

const ImageViewer = ({ isSlideOverOpen, setIsSlideOverOpen, onSave }) => {
  const {
    selectedFieldData,
    selectedFieldReference,
    pdfs
  } = useLocation();

  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const [viewerHeight, setViewerHeight] = useState(500);
  const [slideOverWidth, setSlideOverWidth] = useState(500);
  const [temporaryFieldData, setTemporaryFieldData] = useState(selectedFieldData || {});
  const [temporaryReference, setTemporaryReference] = useState(selectedFieldData?.display?.reference || {page_number : '', pdf_id : null});
  const [customFieldName, setCustomFieldName] = useState(selectedFieldData?.display?.value || "");
  const [customReference, setCustomReference] = useState(temporaryReference);
  const [debouncedReference, setDebouncedReference] = useState(temporaryReference);
  const [error, setError] = useState(null);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const scrollRef = useRef(null);
  const [refPdf, setRefPdf] = useState(selectedFieldData?.display?.reference);



  // console.log("selectedFieldData",selectedFieldData);
  // console.log("selectedFieldReference",selectedFieldReference)
  useEffect(() => {
    const handleResize = () => {
      setViewerHeight(window.innerHeight - 200);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // Update temporary state when props change
    setTemporaryFieldData(selectedFieldData || {});
    setTemporaryReference(selectedFieldData?.display?.reference || "");
    setCustomFieldName(selectedFieldData?.display?.value || "");
    setCustomReference(selectedFieldData?.display?.reference || "");
    setDebouncedReference(selectedFieldData?.display?.reference || "");
    setRefPdf(selectedFieldData?.display?.reference);
    setError(null); // Reset error state when field data changes
  }, [selectedFieldData]);

  const handleOptionClick = (key) => {
    setRefPdf(selectedFieldData?.options?.[key]?.reference);
    const newReference = selectedFieldData?.options?.[key]?.reference || {};
    setTemporaryFieldData({
      ...selectedFieldData,
      display: { ...selectedFieldData?.display, value: key, reference: newReference },
    });
    setTemporaryReference(newReference);
    setCustomFieldName(key);
    setCustomReference(newReference);
    setDebouncedReference(newReference);
    setError(null); // Reset error state on option click
    setUnsavedChanges(true); // Track unsaved changes
    if (scrollRef.current) {
      scrollRef.current.scrollTop = 0; // Scroll to top when any option is selected
    }
  };

  const handleCustomFieldNameChange = (e) => {
    const value = e.target.value;
    setCustomFieldName(value);
    setUnsavedChanges(true); // Mark changes as unsaved
    console.log("value:",value,temporaryFieldData);
    if (temporaryFieldData?.options && temporaryFieldData.options[value]) {
      setCustomReference(temporaryFieldData.options[value].reference);
      setDebouncedReference(temporaryFieldData.options[value].reference);
      setError(null); // Reset error state on field name change
    } else {
      setCustomReference({
        ...refPdf,
      });
      setDebouncedReference("");
    }
  };

  const handleCustomReferenceChange = (e) => {
    const value = e.target.value;
    setCustomReference({
      ...refPdf,
      page_number : value
    });
    setRefPdf({
      ...refPdf,
      page_number : value
    });
    setUnsavedChanges(true); // Mark changes as unsaved

    // delay to load or hit api
    clearTimeout(window.debounceTimer);
    window.debounceTimer = setTimeout(() => {

      setDebouncedReference(value);
    }, 1000);
  };

  const handleSave = () => {
    console.log("customFieldName, customReference",customFieldName, customReference);
    onSave(customFieldName, customReference);
    setUnsavedChanges(false); // Mark changes as saved
  };

  const handleClose = () => {
    if (unsavedChanges) {
      // setShowConfirmationModal(true); // Show confirmation modal if there are unsaved changes
    } else {
      setIsSlideOverOpen(false); // Close slide-over if no unsaved changes
    }
  };

  const confirmClose = () => {
    setShowConfirmationModal(false); // Hide confirmation modal
    setIsSlideOverOpen(false); // Close slide-over
  };

  const cancelClose = () => {
    setShowConfirmationModal(false); // Hide confirmation modal
  };

  const handleResizeMouseDown = (e) => {
    document.addEventListener("mousemove", handleResizeMouseMove);
    document.addEventListener("mouseup", handleResizeMouseUp);
  };

  const handleResizeMouseMove = (e) => {
    setSlideOverWidth(window.innerWidth - e.clientX);
  };

  const handleResizeMouseUp = () => {
    document.removeEventListener("mousemove", handleResizeMouseMove);
    document.removeEventListener("mouseup", handleResizeMouseUp);
  };

  const renderViewer = () => {
    if (!debouncedReference) return <p className="text-white">Please enter a reference number</p>;

    return (
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
        <div
          style={{
            border: "1px solid rgba(0, 0, 0, 0.3)",
            height: `${viewerHeight}px`,
          }}
        >
          {/* <Viewer
            // fileUrl={pdfs?.[0]['path']}
            fileUrl={"https://datadrill-dev.s3.ap-south-1.amazonaws.com/66abc9b94e01616889088601/66eb22cc02d6f3446a63a481/images/2.png"}
            // fileUrl={`https://datadrill-dev.s3.ap-south-1.amazonaws.com/${pdfs?.[0]?.["organisation_id"]}/${refPdf.pdf_id}/images/${refPdf.page_number}.png`}
            plugins={[defaultLayoutPluginInstance]}
            onLoadError={() => setError('PDF not found')}
          /> */}
          {refPdf?.page_number ?<img src={`https://datadrill-dev.s3.ap-south-1.amazonaws.com/${pdfs?.[0]?.["organisation_id"]}/${refPdf?.pdf_id}/images/${refPdf?.page_number}.png`} />:'Page is not configured'}
        </div>
      </Worker>
    );
  };

  return (
    <div
      className={`fixed inset-y-0 right-0 flex transform transition-transform duration-300 bg-indigo-950 ${
        isSlideOverOpen ? "translate-x-0" : "translate-x-full"
      }`}
      style={{ width: `${slideOverWidth}px` }}
    >
      <div className="relative w-full h-full shadow-xl overflow-y-auto">
        <div
          className="absolute inset-y-0 left-0 flex items-center justify-center cursor-pointer"
          onClick={handleClose}
        >
          <ChevronLeftIcon className="h-6 w-6 text-gray-500 hover:text-gray-600" />
        </div>
        <div ref={scrollRef} className="h-full flex flex-col py-6 pr-4 pl-14 sm:pr-4 sm:pl-14 bg-indigo-950 overflow-y-scroll">
          <div className="flex justify-between">
            <button
              className="text-gray-500 hover:text-gray-600"
              onClick={handleClose}
            >
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>

          <div className="mt-4 flex justify-between items-center">
            <h2 className="text-white text-xl font-bold">{selectedFieldReference}</h2>
            { (
              <div className="flex justify-end space-x-2">
                <button
                  className="px-4 py-2 bg-blue-500 text-white rounded"
                  onClick={handleSave}
                >
                  Save
                </button>
              </div>
            )}
          </div>

          {temporaryFieldData && Object.keys(temporaryFieldData).length > 0 ? (
            <>
              <div className="mt-4">
                <label className="block text-white text-sm font-medium mb-2">Field Name</label>
                <input
                  type="text"
                  value={customFieldName}
                  onChange={handleCustomFieldNameChange}
                  className="block w-full px-3 py-2 mb-4 text-black border rounded"
                />

                <label className="block text-white text-sm font-medium mb-2">Image Reference Number</label>
                <input
                  type="text"
                  value={refPdf?.page_number}
                  onChange={handleCustomReferenceChange}
                  className="block w-full px-3 py-2 mb-4 text-black border rounded"
                />
              </div>

              <div className="mt-4">
                {error ? (
                  <p className="text-red-500">{error}</p>
                ) : (
                  renderViewer()
                )}
              </div>

              {temporaryFieldData?.options && (
                <div className="mt-4 text-white">
                  <h3 className="text-lg font-medium">Suggestions for {selectedFieldReference}</h3>
                  <table className="min-w-full bg-white">
                    <thead>
                      <tr>
                        <th className="py-2 px-4 border text-black">Options</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.keys(temporaryFieldData?.options).map((key) => (
                        <tr key={key} onClick={() => handleOptionClick(key)}>
                          <td className="py-2 px-4 border cursor-pointer text-black">{key}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}

              
            </>
          ) : (
            <p className="text-white">No data available</p>
          )}
        </div>

        <div
          className="absolute inset-y-0 left-0 w-10 bg-gray-200 flex items-center justify-center cursor-ew-resize"
          onMouseDown={handleResizeMouseDown}
        >
          <button className="w-full h-full cursor-ew-resize">
            <div className="w-2 h-full bg-gray-400"></div>
          </button>
        </div>

        {showConfirmationModal && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-4 rounded">
              <h2 className="text-xl mb-4">Unsaved Changes</h2>
              <p>You have unsaved changes. Do you still want to close?</p>
              <div className="mt-4 flex justify-end space-x-2">
                <button
                  className="px-4 py-2 bg-gray-300 rounded"
                  onClick={cancelClose}
                >
                  Cancel
                </button>
                <button
                  className="px-4 py-2 bg-blue-500 text-white rounded"
                  onClick={confirmClose}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageViewer;
