import React, { useState, useEffect} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { logout, isAuthenticated } from "../config/auth";

function CreateWell() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const location = useLocation();
  const { country, block, field, site, well } = location.state || {};

  const [formState, setFormState] = useState({
    country: country || "",
    block_name: block || "",
    field_name: field || "",
    site_name: site || "",
    well_name: well || "",
    pdfs: [],
  });

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false); // Added loading state
  const [suggestions, setSuggestions] = useState({
    countries: [],
    block_names: [],
    field_names: []
  });
  const navigate = useNavigate();

  useEffect(() => {
    isAuthenticated().then((auth) => {
      if (!auth) {
        navigate("/login");
      }
    })

    // Fetch suggestions from the backend
    fetch('/v1/well/get-wells-aggregated-data', {
      credentials: 'include',
    })
      .then(response => response.json())
      .then(data => {
        if (data && data.data) {
          console.log("data",data);
          setSuggestions(data.data);
        }
      })
      .catch(error => {
        console.error('Error fetching suggestions:', error);
        setSuggestions({ countries: [], block_names: [], field_names: [] });
      });
  }, [navigate]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleButtonClick = (section) => {
    console.log(`Navigating to ${section}`);
  };

  const handleSelectChange = (selectedOption, { name }) => {
    setFormState({ ...formState, [name]: selectedOption ? selectedOption.value : "" });
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    const pdfs = files.map(file => {
      if (file.type !== 'application/pdf') {
        alert('Only PDF files are allowed');
        return null;
      }
      return { name: file.name, file };
    }).filter(pdf => pdf !== null);
    setFormState({ ...formState, pdfs });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true when form is submitted

    const formData = new FormData();
    formData.append("name", formState.well_name);
    formData.append("country", formState.country);
    formData.append("block_name", formState.block_name);
    formData.append("field_name", formState.field_name);
    formData.append("site_name", formState.site_name);
    formState.pdfs.forEach((pdf) => {
      formData.append("pdfs", pdf.file);
    });

    try {
      const response = await fetch("/v1/well/create-well", {
        method: "POST",
        credentials: 'include',
        body: formData
      });

      const result = await response.json();

      if (result.message === "Well created and PDFs uploaded successfully!") {
        alert(result.message);
        // const jobStart = await fetch("/v1/well/create-well", {
        //   method: "POST",
        //   credentials: 'include',
        //   body: formData
        // });
  
        setIsFormSubmitted(true);
      } else {
        alert("Failed to create well");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while creating the well");
    } finally {
      setLoading(false); // Set loading to false after the upload process is complete
    }
  };

  const handleCreateAnother = () => {
    setFormState({
      country: "",
      pdfs: [],
      block_name: "",
      field_name: "",
      site_name: "",
      well_name: ""
    });
    setIsFormSubmitted(false);
  };

  const handleGoToDashboard = () => {
    navigate("/dashboard");
  };

  const createOptions = (items) => items.map(item => ({ value: item, label: item }));

  return (
    <div className="min-h-screen flex flex-col">
      <div className="flex flex-1">
        <aside
          className={`bg-indigo-950 text-white ${isSidebarOpen ? "w-64" : "w-20"} transition-all duration-300 flex flex-col`}
        >
          <div className={`p-4 ${!isSidebarOpen ? "absolute top-1/4 left-1" : ""}`}>
            <button onClick={toggleSidebar} className="focus:outline-none">
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d={isSidebarOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16m-7 6h7"}
                />
              </svg>
            </button>
          </div>
          {isSidebarOpen && (
            <nav className="mt-4 flex-grow">
              <button
                onClick={() => handleButtonClick("Well location data")}
                className="hover:bg-gray-300 text-white p-2 rounded border border-white text-center mb-5 ml-3 hover:text-black"
                disabled
              >
                Create Well
              </button>
              <ul className="bg-indigo-900">
                <li className="mb-2 flex items-center">
                  <a href="/dashboard" className="block p-4 hover:bg-indigo-950 flex items-center w-full">
                    <svg className="w-6 h-6 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
                    </svg>
                    Dashboard
                  </a>
                </li>
              </ul>
            </nav>
          )}
        </aside>
        <main className="flex-1 p-8 bg-gray-100 flex flex-col">
          <img
            src="/assets/images/logo.png"
            alt="Data Drill"
            className={"transition-all duration-300 w-1/4"}
          />
          {!isFormSubmitted ? (
            <form onSubmit={handleSubmit} className="bg-white p-6 rounded shadow-md mt-5">
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="country">
                  Country
                </label>
                <CreatableSelect
                  name="country"
                  value={createOptions(suggestions.countries).find(option => option.value === formState.country)}
                  onChange={handleSelectChange}
                  options={createOptions(suggestions.countries)}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="block_name">
                  Block Name
                </label>
                <CreatableSelect
                  name="block_name"
                  value={createOptions(suggestions.block_names).find(option => option.value === formState.block_name)}
                  onChange={handleSelectChange}
                  options={createOptions(suggestions.block_names)}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="field_name">
                  Field Name
                </label>
                <CreatableSelect
                  name="field_name"
                  value={createOptions(suggestions.field_names).find(option => option.value === formState.field_name)}
                  onChange={handleSelectChange}
                  options={createOptions(suggestions.field_names)}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="site_name">
                  Site Name
                </label>
                <input
                  type="text"
                  id="site_name"
                  name="site_name"
                  value={formState.site_name}
                  onChange={e => setFormState({ ...formState, site_name: e.target.value })}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="well_name">
                  Well Name
                </label>
                <input
                  type="text"
                  id="well_name"
                  name="well_name"
                  value={formState.well_name}
                  onChange={e => setFormState({ ...formState, well_name: e.target.value })}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="pdfs">
                  Upload PDFs
                </label>
                <input
                  type="file"
                  id="pdfs"
                  name="pdfs"
                  multiple
                  accept="application/pdf"
                  onChange={handleFileChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="mb-4">
                <p className="text-gray-700 text-sm font-bold">Uploaded PDFs:</p>
                <ul>
                  {formState.pdfs.map((pdf, index) => (
                    <li key={index}>{pdf.name}</li>
                  ))}
                </ul>
              </div>
              <div className="flex items-center justify-between">
                <button
                  type="submit"
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  Submit
                </button>
                {loading && (
                  <div className="loader ml-4">Uploading...</div> // Add a loader here
                )}
              </div>
            </form>
          ) : (
            <div className="bg-white p-6 rounded shadow-md mt-5 text-center">
              <p className="text-xl font-bold mb-4">Well created and PDFs uploaded successfully!</p>
              <button
                onClick={handleCreateAnother}
                className="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mr-4"
              >
                Create Another Well
              </button>
              <button
                onClick={handleGoToDashboard}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Go to Dashboard
              </button>
            </div>
          )}
          <footer className="mt-auto py-4 mx-20 flex justify-between">
            <p className="text-center text-gray-600">Privacy Policy</p>
            <p className="text-center text-gray-600">
              © [Your Company Name] [Year]. All rights reserved.
            </p>
          </footer>
        </main>
      </div>
    </div>
  );
}

export default CreateWell;
