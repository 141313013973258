import React, { useState, useEffect, useRef } from 'react';
import './offset-analysis.css';
import NavBar from './Navbar'



function OffSetAnalysis() {
  const [jsonData, setJsonData] = useState({});
  const [selectedWells, setSelectedWells] = useState({});
  const [selectedFormation, setSelectedFormation] = useState(null);
  const [tooltip, setTooltip] = useState({ visible: false, content: '', x: 0, y: 0 });
  const [selectedWellData, setSelectedWellData] = useState(null);
  const [selectedFormationData, setSelectedFormationData] = useState(null);
  const canvasRef = useRef(null);
  const [maxDepthValue, setMaxDepthValue] = useState(null);
  const scaleSteps = 300;

  const [coordinates, setCoordinates] = useState({
    lat : null,
    log :null
  });
  const [nearbyWells,setNearByWells] = useState([]);




  useEffect(() => {
      setJsonData({});
      setSelectedWells({});
      setSelectedWellData(null);
      setSelectedFormation(null);
  }, [nearbyWells]);


  useEffect(() => {
  
    const params = new URLSearchParams(window.location.search);
    const lat = params.get('lat');
    const lon = params.get('long');
    if(lat && lon) {
      setCoordinates({
        lat,
        lon
      });
    }else{
      console.log("Invalid Coordinates");
    }
  }, [window.location.search]);


  useEffect(() => {
    let maxWellDepth = 0; // Initialize a variable to keep track of the maximum depth
  
    Object.keys(jsonData).forEach((well) => {
      if (selectedWells[well]) {
        const wellDepth = getWellDepth(jsonData[well]);
        if (wellDepth > maxWellDepth) {
          maxWellDepth = wellDepth; // Update maxWellDepth if a greater depth is found
        }
      }
    });
  
    // Do something with maxWellDepth, e.g., set state or log it
    console.log('Maximum Well Depth:', maxWellDepth);
    setMaxDepthValue(maxWellDepth+scaleSteps);
  }, [jsonData, selectedWells]);


  const toggleWellSelection = (well) => {
    console.log("selectedWells",selectedWells,well);
    let isEnabled = true;
    setSelectedWells((prev) => {
      if(prev[well]) {
        isEnabled = false;
      }
      return {
        ...prev,
        [well]: !prev[well],
      }
    });
    if(isEnabled) {
      getWellData(well);
    }
  };



  const fetchWellLocationData = async (wellId) => {
    try {
      const response = await fetch(`/v1/well/get-well-location-data/${wellId}`, {
        method: "GET",
        credentials: 'include',
      });
      if (response.ok) {
        const data = await response.json();
        if (data && data.data && data.data.wellLocation) {
          return data.data.wellLocation;
        }
      }
      return false;
    } catch (err) {
      console.log(err);
      return false;
    }
  };
  const fetchFormationData = async (wellId) => {
    try {
      const response = await fetch(`/v1/well/get-formation-data/${wellId}`,{
        method: "GET",
        credentials: 'include',
      });
      if(response) {
        const data = await response.json();
        console.log("data:",data);
        if(data && data.data) {
          return data.data;
        }
      }
    } catch (err) {
      console.log("Err while fetching",err);
      return false;
    }
  };


  const fetchComplicationData = async (wellId) => {
    try {
      const response = await fetch(`/v1/well/get-complication-data/${wellId}`,{
        method: "GET",
        credentials: 'include',
      });
      if(response) {
        const data = await response.json();
        console.log("data:",data);
        if(data && data.data) {
          return data.data;
        }
      }
      return false;
    } catch (err) {
      console.log("Err while fetching",err);
      return false;
    }
  };

  const getWellData = async (wellId) => {
      try{
        const wellLocationData = await fetchWellLocationData(wellId);
        const formationData = await fetchFormationData(wellId);
        const complicationData = await fetchComplicationData(wellId);
        console.log("Generated data:::::",formationData, complicationData );
        if(wellLocationData && formationData && complicationData) {
            setJsonData((prev) => ({
              ...prev,
              [wellId]: {
                wellLocation : wellLocationData,
                formations : formationData,
                complications : complicationData
              },
            }))
            console.log("Generated Data1:",{
              ...jsonData,
              [wellId]: {
                wellLocation : wellLocationData,
                formations : formationData,
                complications : complicationData
              },
            });
        }else{
          console.log("Error with data in either formationData or complicationData");
        }
      }catch(err){
        console.log(err);
      }
  }


  const paddingTop = 40;

  const wellWidth = 60;
  const wellSpacing = 60;


  const [formationColors,setFormationColors] = useState({});
  const [usedColors, setUsedColors] = useState(new Set());

  const generateRandomColor = () => {
    let color;
    do {
      // Generate a random hex color
      color = `#${Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0')}`;
    } while (isShadeOfWhite(color));
    
    return color;
  };
  
  const isShadeOfWhite = (color) => {
    const rgb = parseInt(color.slice(1), 16);
    const r = (rgb >> 16) & 0xff;
    const g = (rgb >> 8) & 0xff;
    const b = rgb & 0xff;
  
    // Check if the color is close to white
    const threshold = 200; // Adjust this threshold as needed
    return r > threshold && g > threshold && b > threshold;
  };
  
  const getFormationColor = (formation) => {
    if (formationColors[formation]) {
      return formationColors[formation];
    }
    let newColor;
    do {
      newColor = generateRandomColor();
    } while (usedColors.has(newColor)); // Ensure the color hasn't been used
  
    setFormationColors({
      ...formationColors,
      [formation] : newColor
    })
    setUsedColors((prevSet)=>{
      const newSet = new Set(prevSet); 
      newSet.add(newColor);
      return newSet;
    })
    return newColor;
  };


  const getWellDepth = (wellData) => {
    console.log("Depth",parseFloat(wellData?.["wellLocation"]?.["total_depth"]?.["display"]?.["value"]));
    return parseFloat(wellData?.["wellLocation"]?.["total_depth"]?.["display"]?.["value"])
  }


  const getComplicationResult = () => {
    const complications = [];

    Object.keys(jsonData).forEach((well) => {
      if (selectedWells[well]) {
        let complicationData = jsonData[well]?.complications?.["acceptedValues"];
        const wellComplications = (
          <div key={well}>
            <h3>{jsonData[well]?.["wellLocation"]?.["well_name"]?.display?.value}</h3>
            {Object.keys(complicationData || {}).map((depth) => {
              const convertedDepth = parseFloat(depth);
              if (!convertedDepth) return null;

              if (convertedDepth >= selectedFormationData.startDepth && convertedDepth <= selectedFormationData.endDepth) {
                const { complication_type, complication_description, complication_problem_resolution } = complicationData[depth]; // Assuming these keys exist
            
                return (
                  <div className="complication" key={depth}>
                    <h4>Complication Type: {complication_type?.display?.value || 'N/A'}</h4>
                    <p><strong>Depth:</strong> {depth} feet</p>
                    <p><strong>Description:</strong> {complication_description?.display?.value || 'No description available.'}</p>
                    <p><strong>Solution:</strong> {complication_problem_resolution?.display?.value || 'No solution provided.'}</p>
                    <hr />
                  </div>
                );
              }
              return null;
            })}
            <br />
          </div>
        );
        complications.push(wellComplications);
      }
    });

    return complications;
  };

  const drawGraph = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Draw depth scale
    ctx.fillStyle = '#000';
    ctx.fillText('0 ft', 30, paddingTop); // Add "0 ft" label at the top
    for (let i = 0; i <= maxDepthValue; i += scaleSteps) {
      const yPos = paddingTop + (i * (canvas.height - paddingTop)) / maxDepthValue; // Adjust y position for padding
      ctx.fillText(`${Math.round(i)} ft`, 30, yPos);
      ctx.beginPath();
      ctx.moveTo(70, yPos);
      ctx.lineTo(90, yPos);
      ctx.stroke();
    }

    // Draw wells and formations
    let wellIndex = 0;
    Object.keys(jsonData).forEach((well) => {
      if (selectedWells[well]) {
        const startX = 100 + wellIndex * (wellWidth + wellSpacing);
        wellIndex++;

        const maxWellDepth = getWellDepth(jsonData[well]);
        const maxDepthY = paddingTop + (maxWellDepth * (canvas.height - paddingTop)) / maxDepthValue;

        ctx.fillStyle = '#ffffff';
        ctx.fillRect(startX, paddingTop, wellWidth, maxDepthY - paddingTop); // Adjust for padding
        ctx.strokeStyle = '#333';
        ctx.lineWidth = 2;
        ctx.strokeRect(startX, paddingTop, wellWidth, maxDepthY - paddingTop); // Adjust for padding

        Object.keys(jsonData[well]?.formations?.["acceptedValues"]).forEach((formation, formationIndex) => {
          const startDepth = parseInt(jsonData[well].formations["acceptedValues"][formation]?.formation_formation_start_md?.display?.value);
          const endDepth = parseInt(jsonData[well].formations["acceptedValues"][formation]?.formation_formation_end_md?.display?.value);
          const formationYStart = paddingTop + (startDepth * (canvas.height - paddingTop)) / maxDepthValue; // Adjust for padding
          const formationYEnd = paddingTop + (endDepth * (canvas.height - paddingTop)) / maxDepthValue; // Adjust for padding

          ctx.fillStyle = getFormationColor(formation);
          ctx.fillRect(startX, formationYEnd, wellWidth, formationYStart - formationYEnd);
        });
      }
    });
  };

  const handleMouseMove = (event) => {
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    let hoverFormation = null;
    let formationInfo = {};

    Object.keys(jsonData).forEach((well) => {
      if (selectedWells[well]) {
        const wellX = 100 + Object.keys(selectedWells).filter(key => selectedWells[key]).indexOf(well) * (60 + 60);
        const wellWidth = 60;
        if (x >= wellX && x <= wellX + wellWidth) {
          Object.keys(jsonData[well].formations?.["acceptedValues"]).forEach((formation) => {
            const startDepth = parseInt(jsonData[well].formations?.["acceptedValues"][formation]?.formation_formation_start_md?.display.value);
            const endDepth = parseInt(jsonData[well].formations?.["acceptedValues"][formation]?.formation_formation_end_md?.display.value);
            const formationYStart = paddingTop + (startDepth * (canvas.height - paddingTop)) / maxDepthValue; // Adjust for padding
            const formationYEnd = paddingTop + (endDepth * (canvas.height - paddingTop)) / maxDepthValue; // Adjust for padding

            if (y <= formationYEnd && y >= formationYStart) {
              hoverFormation = jsonData[well].formations?.["acceptedValues"][formation];
              formationInfo = {
                name: formation,
                start: startDepth,
                end: endDepth
              };
            }
          });
        }
      }
    });

    if (hoverFormation) {
      setSelectedFormationData({
        startDepth : formationInfo.start,
        endDepth : formationInfo.end
      })
      setTooltip({
        visible: true,
        content: `Formation: ${formationInfo.name}\nDepth: ${formationInfo.start} - ${formationInfo.end} ft\nDescription: ${hoverFormation?.formation_description?.display.value}`,
        x: event.clientX,
        y: event.clientY
      });
    } else {
      setTooltip({ visible: false, content: '', x: 0, y: 0 });
    }
  };

  const handleClick = (event) => {
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    let hoverFormation = null;
    let formationInfo = {};

    Object.keys(jsonData).forEach((well) => {
      if (selectedWells[well]) {
        const wellX = 100 + Object.keys(selectedWells).filter(key => selectedWells[key]).indexOf(well) * (60 + 60);
        const wellWidth = 60;
        if (x >= wellX && x <= wellX + wellWidth) {
          Object.keys(jsonData[well].formations?.["acceptedValues"]).forEach((formation) => {
            const startDepth = parseInt(jsonData[well].formations?.["acceptedValues"][formation]?.formation_formation_start_md?.display.value);
            const endDepth = parseInt(jsonData[well].formations?.["acceptedValues"][formation]?.formation_formation_end_md?.display.value);
            const formationYStart = paddingTop + (startDepth * (canvas.height - paddingTop)) / maxDepthValue; // Adjust for padding
            const formationYEnd = paddingTop + (endDepth * (canvas.height - paddingTop)) / maxDepthValue; // Adjust for padding

            if (y <= formationYEnd && y >= formationYStart) {
              hoverFormation = jsonData[well].formations?.["acceptedValues"][formation];
              formationInfo = {
                name: formation,
                start: startDepth,
                end: endDepth
              };
              setSelectedFormation(formation);
              setSelectedFormationData({
                startDepth,
                endDepth
              })
            }
          });
        }
      }
    });

    if (hoverFormation) {
      setSelectedFormationData({
        startDepth : formationInfo.start,
        endDepth : formationInfo.end
      })
      // setTooltip({
      //   visible: true,
      //   content: `Formation: ${formationInfo.name}\nDepth: ${formationInfo.start} - ${formationInfo.end} ft\nDescription: ${hoverFormation?.formation_description?.display.value}`,
      //   x: event.clientX,
      //   y: event.clientY
      // });
    } else {
      // setTooltip({ visible: false, content: '', x: 0, y: 0 });
    }
  }


  useEffect(() => {
    if (selectedFormation) {
      // Filter and get data for all wells that have the selected formation
      const wellsWithSelectedFormation = Object.keys(jsonData).filter((well) =>
        jsonData[well].formations["acceptedValues"][selectedFormation]
      );

      // Map the well data for the selected formation
      const formationData = wellsWithSelectedFormation.reduce((acc, well) => {
        acc[well] = jsonData[well].formations["acceptedValues"][selectedFormation];
        return acc;
      }, {});

      // Update the selected well data with formation data
      setSelectedWellData(formationData);
    } else {
      setSelectedWellData(null);
    }
  }, [selectedFormation, jsonData]);

  useEffect(() => {
    drawGraph();
  }, [jsonData, selectedWells]);


  const [radius, setRadius] = useState(50);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSliderChange = (event) => {
    const value = event.target.value;
    setRadius(value);
  };

  const handleInputChange = (event) => {
    const value = Math.max(1, Math.min(event.target.value, 6371)); // Clamp value between 1 and 6371
    setRadius(value);
  };


  const fetchData = async () => {
    try {
      if(coordinates.lat == null && coordinates.lon == null) {
        return;
      }
      const response = await fetch(`/v1/well/get-near-by-wells?lat=${coordinates.lat}&lon=${coordinates.lon}&radius=${radius}`,{
        method: "GET",
        credentials: 'include',
      });
      if(response) {
        const data = await response.json();
        console.log("data:",data);
        if(data && data.data && data.data.wells && data.data.wells.length) {
          setNearByWells(data.data.wells);
          console.log("Response:",data);
        }
      }
    } catch (err) {
      console.log("Err while fetching",err);
      // setData(null);
    }
  };





  const handleSubmit = async () => {
    setLoading(true);
    setError('');
    
    try {
      await fetchData();
      // const response = await axios.post('/v1/well/get-near-by-wells', { lat : 100, long: 101, radius });
      // alert(`Response from server: ${response.data.message}`);
    } catch (err) {
      setError('Error submitting radius. Please try again.');
    } finally {
      setLoading(false);
    }
  };


  function haversineDistance(coords1, coords2) {
    const toRad = (value) => (value * Math.PI) / 180;

    const lat1 = coords1.lat;
    const lon1 = coords1.lng;
    const lat2 = coords2.lat;
    const lon2 = coords2.lng;

    const R = 6371; // Radius of the Earth in kilometers

    const dLat = toRad(lat2 - lat1);
    const dLon = toRad(lon2 - lon1);

    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
    
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return (R * c).toFixed(2); // Distance in kilometers
}


  const getDistance = (well) => {
    if(!well.location || well.location.length < 2) {
      return "-";
    }
    const coordinate1 = {
      lat : well?.location?.coordinates?.[1],
      lng : well?.location?.coordinates?.[0]
    }
    return haversineDistance(coordinate1, {
      lat : coordinates.lat,
      lng : coordinates.lon
    })
  }


  return (
    <div className="offset-analysis">
      <NavBar />
      <div style={{ width : '25%','margin-top' : '45px',display: 'flex', backgroundColor: '#f4f4f4' }}>
        <div className="wells-list">
          <div className="content">
            <div className="card" style={{ background: 'white', borderRadius: '8px', boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)', padding: '20px', margin: '20px 0' }}>
              <h1>Select Range of Nearby Wells for Offset Analysis</h1>
              <div className="slider-container" style={{ marginTop: '20px' }}>
                <label htmlFor="radiusSlider">Radius: <span id="radiusValue">{radius}</span> km</label>
                <input
                  type="number"
                  min="1"
                  max="6371"
                  value={radius}
                  onChange={handleInputChange}
                  style={{ marginLeft: '10px', border: '1px solid #ccc', width: '100px', padding: '5px', fontSize: '16px' }}
                />
                <input
                  type="range"
                  id="radiusSlider"
                  min="1"
                  max="6371"
                  value={radius}
                  onChange={handleSliderChange}
                  style={{ marginTop: '10px', width: '100%' }}
                />
              </div>
              {error && <div style={{ color: 'red', textAlign: 'center' }}>{error}</div>}
              <div className="info" style={{ textAlign: 'left', marginTop: '20px' }}>
                <button onClick={handleSubmit} disabled={loading} style={{ padding: '10px 20px', backgroundColor: '#007bff', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>
                  {loading ? 'Searching...' : 'Search'}
                </button>
              </div>
            </div>
          </div>
          <h2 style={{fontWeight:700,margin : "20px"}}>Wells</h2>
          {nearbyWells.map((well, index) => (
            <div key={index} style={{ margin: '10px 0' }}>
              <div style={{ backgroundColor: selectedWells[well.well_id] ? '#e0e0e0' : '#fff', borderRadius: '8px', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)', padding: '10px' }}>
                <label style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                  <input
                    type="checkbox"
                    checked={!!selectedWells[well.well_id]}
                    onChange={() => toggleWellSelection(well.well_id)}
                    style={{ marginRight: '10px' }}
                  />
                  <div style={{ flexGrow: 1 }}>
                    <strong>{well.ud_well_name}</strong>
                  </div>
                </label>
                <hr style={{ margin: '10px 0' }} />
                <div style={{ padding: '5px 0' }}>
                  <div> Distance: {getDistance(well)} km</div>
                  <div>Latitude: {well?.location?.coordinates?.[1]} &deg;</div>
                  <div>Longitude: {well?.location?.coordinates?.[0]} &deg;</div>
                </div>
              </div>
            </div>
          ))}
        </div>
        </div>
        <div className="canvas-container">
          <canvas
            ref={canvasRef}
            width={800}
            height={1000}
            onMouseMove={handleMouseMove}
            onClick={handleClick}
          />
          {tooltip.visible && (
            <div className="tooltip" style={{ left: tooltip.x + 10, top: tooltip.y + 10, background: '#fff', border: '1px solid #ccc', padding: '5px', zIndex: 1000 }}>
              {tooltip.content}
            </div>
          )}
        </div>
        <div className="right-panel">
          <h1>Wells Complications</h1>
            {selectedFormationData ? getComplicationResult() : ''}
        </div>
    </div>
  );
}

export default OffSetAnalysis;
