import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

function Sidebar({
  isSidebarOpen,
  toggleSidebar,
  selectedCountry,
  setSelectedCountry,
  selectedBlock,
  setSelectedBlock,
  selectedField,
  setSelectedField,
  selectedSite,
  setSelectedSite,
  selectedWell,
  setSelectedWell,
  activeMenu,
  setActiveMenu,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState({
    countries: [],
    blocks: [],
    fields: [],
    sites: [],
    wells: [],
  });
  const [wellNameFilter, setWellNameFilter] = useState("");

  const fetchData = async (type, filters) => {
    try {
      const response = await fetch(`/v1/well/get-wells-data-filter?type=${type}&filter=${JSON.stringify(filters)}`, {
        credentials: 'include',
      });
      const result = await response.json();
      if (result && result.data) {
        setData(prevData => ({
          ...prevData,
          [type]: result.data[type],
          wells: result.data.wells,
        }));
      }
    } catch (error) {
      console.error(`Error fetching ${type}:`, error);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setSelectedCountry(params.get("country") || "");
    setSelectedBlock(params.get("block") || "");
    setSelectedField(params.get("field") || "");
    setSelectedSite(params.get("site") || "");
    const wellId = params.get("well_id") || "";
    const wellName = params.get("well_name") || "";
    setSelectedWell({ well_id: wellId, well_name: wellName });
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (selectedCountry) params.set("country", selectedCountry);
    else params.delete("country");

    if (selectedBlock) params.set("block", selectedBlock);
    else params.delete("block");

    if (selectedField) params.set("field", selectedField);
    else params.delete("field");

    if (selectedSite) params.set("site", selectedSite);
    else params.delete("site");

    if (selectedWell.well_id) {
      params.set("well_id", selectedWell.well_id);
      params.set("well_name", selectedWell.well_name);
    } else {
      params.delete("well_id");
      params.delete("well_name");
    }

    navigate(`?${params.toString()}`, { replace: true });
  }, [selectedCountry, selectedBlock, selectedField, selectedSite, selectedWell, navigate]);

  useEffect(() => {
    fetchData("countries", {});
  }, []);

  useEffect(() => {
    if (selectedCountry) fetchData("blocks", { country: selectedCountry });
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedBlock) fetchData("fields", { country: selectedCountry, block: selectedBlock });
  }, [selectedBlock]);

  useEffect(() => {
    if (selectedField) fetchData("sites", { country: selectedCountry, block: selectedBlock, field: selectedField });
  }, [selectedField]);

  useEffect(() => {
    if (selectedSite) fetchData("wells", { country: selectedCountry, block: selectedBlock, field: selectedField, site: selectedSite });
  }, [selectedSite]);

  const toggleSubMenu = (menu) => {
    setActiveMenu(activeMenu === menu ? "" : menu);
  };

  const handleCountryChange = (country) => {
    setSelectedCountry(country);
    setSelectedBlock(""); 
    setSelectedField("");
    setSelectedSite("");
    setSelectedWell({ well_id: "", well_name: "" });
  };

  const handleBlockChange = (block) => {
    setSelectedBlock(block);
    setSelectedField(""); 
    setSelectedSite("");
    setSelectedWell({ well_id: "", well_name: "" });
  };

  const handleFieldChange = (field) => {
    setSelectedField(field);
    setSelectedSite(""); 
    setSelectedWell({ well_id: "", well_name: "" });
  };

  const handleSiteChange = (site) => {
    setSelectedSite(site);
    setSelectedWell({ well_id: "", well_name: "" });
  };

  const handleCreateWell = () => {
    navigate("/create-well", {
      state: {
        country: selectedCountry,
        block: selectedBlock,
        field: selectedField,
        site: selectedSite,
      },
    });
  };

  return (
    <aside className={`bg-indigo-950 text-white ${isSidebarOpen ? "w-64" : "w-20"} transition-all duration-300 flex flex-col`}>
      <div className={`p-4 ${!isSidebarOpen ? "absolute top-1/4 left-1" : ""}`}>
        <button onClick={toggleSidebar} className="focus:outline-none">
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d={isSidebarOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16m-7 6h7"}
            />
          </svg>
        </button>
      </div>
      {isSidebarOpen && (
        <nav className="mt-4 flex-grow">
          <button onClick={handleCreateWell} className="hover:bg-gray-300 text-white p-2 rounded border border-white text-center mb-5 ml-3 hover:text-black">
            Create Well
          </button>
          <ul className="bg-indigo-900">
            <li className="flex items-center">
              <a href="#" className="block p-4 flex items-center bg-green-600 w-full">
                Data Filter
              </a>
            </li>
            <li>
                  <div className="block ml-0">
                    <input
                      type="text"
                      placeholder="Enter well name"
                      value={wellNameFilter}
                      onChange={(e) => setWellNameFilter(e.target.value)}
                      className="p-1 rounded border border-gray-300 text-black" 
                    />
                  </div>
                </li>
            <li>
              <div onClick={() => toggleSubMenu("Country")} className="block p-4 hover:bg-indigo-950 flex items-center justify-between cursor-pointer">
                <span className="flex items-center">Country {selectedCountry && `(${selectedCountry})`}</span>
                <svg className={`w-4 h-4 transition-transform transform ${activeMenu === "Country" ? "rotate-180" : ""}`} fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                </svg>
              </div>
              {activeMenu === "Country" && (
                <ul>
                  {data.countries.map(country => (
                    <li key={country}>
                      <label className="flex items-center pl-6 p-4 justify-between bg-indigo-950">
                        <span>{country}</span>
                        <input type="radio" name="country" value={country} checked={selectedCountry === country} onChange={() => handleCountryChange(country)} className="form-radio" />
                      </label>
                    </li>
                  ))}
                </ul>
              )}
            </li>
            {selectedCountry && (
              <li>
                <div onClick={() => toggleSubMenu("Block / License")} className="block p-4 hover:bg-indigo-950 flex items-center justify-between cursor-pointer">
                  <span className="flex items-center">Block / License {selectedBlock && `(${selectedBlock})`}</span>
                  <svg className={`w-4 h-4 transition-transform transform ${activeMenu === "Block / License" ? "rotate-180" : ""}`} fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                  </svg>
                </div>
                {activeMenu === "Block / License" && (
                  <ul>
                    {data.blocks.map(block => (
                      <li key={block}>
                        <label className="flex items-center pl-6 p-4 justify-between bg-indigo-950">
                          <span>{block}</span>
                          <input type="radio" name="block" value={block} checked={selectedBlock === block} onChange={() => handleBlockChange(block)} className="form-radio" />
                        </label>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            )}
            {selectedBlock && (
              <li>
                <div onClick={() => toggleSubMenu("Field name")} className="block p-4 hover:bg-indigo-950 flex items-center justify-between cursor-pointer">
                  <span className="flex items-center">Field name {selectedField && `(${selectedField})`}</span>
                  <svg className={`w-4 h-4 transition-transform transform ${activeMenu === "Field name" ? "rotate-180" : ""}`} fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                  </svg>
                </div>
                {activeMenu === "Field name" && (
                  <ul>
                    {data.fields.map(field => (
                      <li key={field}>
                        <label className="flex items-center pl-6 p-4 justify-between bg-indigo-950">
                          <span>{field}</span>
                          <input type="radio" name="field" value={field} checked={selectedField === field} onChange={() => handleFieldChange(field)} className="form-radio" />
                        </label>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            )}
            {selectedField && (
              <li>
                <div onClick={() => toggleSubMenu("Site")} className="block p-4 hover:bg-indigo-950 flex items-center justify-between cursor-pointer">
                  <span className="flex items-center">Site {selectedSite && `(${selectedSite})`}</span>
                  <svg className={`w-4 h-4 transition-transform transform ${activeMenu === "Site" ? "rotate-180" : ""}`} fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                  </svg>
                </div>
                {activeMenu === "Site" && (
                  <ul>
                    {data.sites.map(site => (
                      <li key={site}>
                        <label className="flex items-center pl-6 p-4 justify-between bg-indigo-950">
                          <span>{site}</span>
                          <input type="radio" name="site" value={site} checked={selectedSite === site} onChange={() => handleSiteChange(site)} className="form-radio" />
                        </label>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            )}
            {selectedWell && (
              <li>
                <div onClick={() => toggleSubMenu("Well name")} className="block p-4 hover:bg-indigo-950 flex items-center justify-between cursor-pointer">
                  <span className="flex items-center">Well name ({selectedWell.well_name || "Select a Well"})</span>
                  <svg className={`w-4 h-4 transition-transform transform ${activeMenu === "Well name" ? "rotate-180" : ""}`} fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                  </svg>
                </div>
                { (
                  <ul>
                    {data?.wells?.filter(well => wellNameFilter === "" || well?.ud_well_name?.toLowerCase().includes(wellNameFilter?.toLowerCase())).map(well => (
                      <li key={well.well_id}>
                        <label className="flex items-center pl-6 p-4 justify-between bg-indigo-950">
                          <span>{well.ud_well_name || well.well_id}</span>
                          <input type="radio" name="well" value={well.ud_well_name} checked={selectedWell.well_id === well.well_id} onChange={() => setSelectedWell({ well_id: well.well_id, well_name: well.ud_well_name })} className="form-radio" />
                        </label>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            )}
          </ul>
        </nav>
      )}
    </aside>
  );
}

export default Sidebar;
