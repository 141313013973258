import React from 'react';

const AppBar = () => {
  const appBarStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#1E1B4B',
    padding: '10px 20px',
    position: 'fixed', // Fixes the bar at the top
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1000,
  };

  const headingStyle = {
    fontSize: '28px', // Larger font size for emphasis
    color: 'white',
    margin: 0,
  };

  const navStyle = {
    display: 'flex',
  };

  const linkStyle = {
    color: 'white',
    margin: '0 15px',
    textDecoration: 'none',
  };

  return (
    <header style={appBarStyle}>
      <h1 style={headingStyle}>Offset Well Analysis</h1>
      <img src="/assets/images/logo.png" alt="Data Drill" className={"transition-all duration-300 w-1/6"} />
    </header>
  );
};

export default AppBar;
