// src/config/auth.js
import Cookies from 'js-cookie';

export const isAuthenticated = () => {
  return new Promise((resolve) => {
    resolve(Cookies.get('user'));  
  });
};

export const login = (email, password) => {
  console.log('Attempting login with:', email, password);
  return fetch('/v1/auth/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email, password }),
    credentials: 'include',
  })
  .then(response => {
    console.log('Received response:', response);
    if (response.ok) {
      return response.json().then(data => {
        Cookies.set('user', email, { expires: 0.0208*12 }); // 30 minutes
        return data;
      });
    } else {
      return response.json().then(data => {
        throw new Error(data.message);
      });
    }
  })
  .catch(error => {
    console.error('Login error:', error); 
    throw error;
  });
};

export const logout = () => {
  return fetch('/v1/auth/logout', {
    method: 'GET',
    credentials: 'include',
  })
  .then(response => {
    if (response.ok) {
      Cookies.remove('user');  // Remove the user cookie
    }
  });
};
