import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import LoginPage from './pages/Login';
import Dashboard from './pages/Dashboard';
import { isAuthenticated } from './config/auth';
import CreateWell from './pages/CreateWell';
import OffSetAnalysis from './page/OffSetAnalysis.js'
import PdfTracker from './components/PdfTracker/index.js';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/dashboard" element={isAuthenticated() ? <Dashboard /> : <Navigate to="/login" />} />
        <Route path="/offset-analysis" element={isAuthenticated() ? <OffSetAnalysis /> : <Navigate to="/login" />} />
        <Route path="/create-well" element={isAuthenticated() ? <CreateWell /> : <Navigate to="/login" />} />
        <Route path="/pdf-tracker" element={isAuthenticated() ? <PdfTracker /> : <Navigate to="/login" />} />
        <Route path="/" element={<Navigate to="/dashboard" />} />

  </Routes>
    </Router>
  );
}

export default App;
