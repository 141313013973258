import React, { useEffect, useState } from 'react';
import AnimatedStatus from './AnimatedStatus';
import './PdfTracker.css'; // Assuming you have a separate CSS file for custom styles

const PdfTracker = () => {
    const [pdfData, setPdfData] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await fetch(`/v1/well/pdfs`, {
                method: "GET",
                credentials: 'include',
            });
            if (response) {
                const data = await response.json();
                if (data && data.data) {
                    setPdfData(data.data);
                }
            }
        } catch (error) {
            console.error('Error fetching PDF data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
        const interval = setInterval(fetchData, 20000); // Refetch every 20 sec
        return () => clearInterval(interval);
    }, []);

    return (
        <div className="pdf-tracker">
            {/* <h1 className="title">PDF Tracker</h1> */}
            {loading ? (
                <div className="loading-spinner">Loading...</div>
            ) : (
                <div className="pdf-cards">
                    {pdfData.map((pdf) => (
                        <div key={pdf._id} className="pdf-card">
                            <h3>{pdf.filename}</h3>
                            {/* <p><strong>Total Page:</strong> {pdf.page}</p> */}
                            {/* <p><strong>Organisation ID:</strong> {pdf.organisation_id}</p> */}
                            {/* <p><strong>User ID:</strong> {pdf.user_id}</p> */}
                            <p><strong>Well Name:</strong> {pdf?.well_id?.name}</p>
                            <p><strong>Status:</strong> <AnimatedStatus status={pdf.status} /></p>
                            <p><strong>Created At:</strong> {new Date(pdf.createdAt).toLocaleString()}</p>
                            <a href={pdf.path} target="_blank" rel="noopener noreferrer" className="view-button">View PDF</a>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default PdfTracker;
