import React from 'react';

const AnimatedStatus = ({ status }) => {
    const statusClasses = {
        pending: 'status-pending',
        uploaded: 'status-uploaded',
        uploading_failed: 'status-uploading-failed',
        processing: 'status-processing',
        completed: 'status-completed',
        failed: 'status-failed',
    };

    return (
        <div className={`status-indicator ${statusClasses[status]}`}>
            {status.charAt(0).toUpperCase() + status.slice(1).replace(/_/g, ' ')}
            <style jsx>{`
                .status-indicator {
                    padding: 10px 15px;
                    border-radius: 5px;
                    color: white;
                    font-weight: bold;
                    text-align: center;
                    transition: transform 0.2s;
                }

                .status-indicator:hover {
                    transform: scale(1.1);
                }

                .status-pending {
                    background-color: orange;
                }

                .status-uploaded {
                    background-color: green;
                }

                .status-uploading-failed {
                    background-color: red;
                }

                .status-processing {
                    background-color: blue;
                }

                .status-completed {
                    background-color: darkgreen;
                }

                .status-failed {
                    background-color: darkred;
                }
            `}</style>
        </div>
    );
};

export default AnimatedStatus;
