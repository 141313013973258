import React, { useState, useEffect } from "react";
import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import InnerImageZoom from 'react-inner-image-zoom';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';

const AddNewFormationEntrySlideOver = ({ isOpen, onClose, onSubmit, data }) => {
  const [formData, setFormData] = useState({});
  const [slideOverWidth, setSlideOverWidth] = useState(1200);

  // useEffect(() => {
  //   if (!isOpen) {
  //     setFormData({});
  //   }
  // }, [isOpen]);

  const handleChange = (e, field) => {
    setFormData({
      ...formData,
      [field]: e.target.value,
    });
  };

  const handleRefChange  = (e, field) => {
    setFormData({
      ...formData,
      [field]: {
        page_number : e.target.value,
        pdf_id : null
      }
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (typeof onSubmit === "function") {
      onSubmit(formData);
    }
    onClose();
  };

  const handleResizeMouseDown = (e) => {
    document.addEventListener("mousemove", handleResizeMouseMove);
    document.addEventListener("mouseup", handleResizeMouseUp);
  };

  const handleResizeMouseMove = (e) => {
    setSlideOverWidth(window.innerWidth - e.clientX);
  };

  const handleResizeMouseUp = () => {
    document.removeEventListener("mousemove", handleResizeMouseMove);
    document.removeEventListener("mouseup", handleResizeMouseUp);
  };

  return (
    <div
      className={`fixed inset-y-0 right-0 flex transform transition-transform duration-300 bg-indigo-950 ${
        isOpen ? "translate-x-0" : "translate-x-full"
      }`}
      style={{ width: `${slideOverWidth}px` }}
    >
      <div className="relative w-full h-full overflow-y-auto">
        <div
          className="absolute inset-y-0 left-0 flex items-center justify-center cursor-pointer"
          onClick={onClose}
        >
          <ChevronLeftIcon className="h-6 w-6 text-gray-500 hover:text-gray-600" />
        </div>
        <div className="pl-16 p-6 flex h-full justify-between items-center">
          <div className="flex-1">
            <h2 className="text-xl font-bold mb-4 text-white">Add New Entry</h2>
            <form onSubmit={handleSubmit} className="grid grid-cols-1 gap-4">
              {[
                "Name",
                "Start MD",
                "Start TVD",
                "End TVD",
                "End MD",
                "Description",
              ].map((field) => (
                <div key={field} className="flex items-center gap-8">
                  <label className="block text-sm font-medium text-white w-32">
                    {field}
                  </label>
                  <input
                    type="text"
                    className="block w-40 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder="Value"
                    onChange={(e) => handleChange(e, field)}
                  />
                  <input
                    type="number"
                    className="block w-40 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder="Reference( Enter pdf page number)"
                    onChange={(e) => handleRefChange(e, `${field}_ref`)}
                  />
                </div>
              ))}
            </form>
            <div className="mt-4 flex justify-start">
              <button
                type="button"
                className="bg-red-500 text-white px-4 py-2 rounded mr-2"
                onClick={onClose}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="bg-blue-500 text-white px-4 py-2 rounded"
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          </div>
          <div
            className="ml-6 flex-shrink-0 flex items-center"
            style={{ width: "600px" }}
          >
            <ImageViewer reference={formData.reference} />
          </div>
        </div>
        <div
          className="absolute inset-y-0 left-0 w-10 bg-gray-200 flex items-center justify-center cursor-ew-resize"
          onMouseDown={handleResizeMouseDown}
        >
          <button className="w-full h-full cursor-ew-resize">
            <div className="w-2 h-full bg-gray-400"></div>
          </button>
        </div>
      </div>
    </div>
  );
};

const ImageViewer = ({ reference }) => {
  if (!reference) {
    return <div className="text-white text-center">No image available</div>;
  }

  const imageUrl = `/images/${reference}.png`;
  console.log("Attempting to load image:", imageUrl);

  return (
    <div className="w-full flex flex-col justify-center items-center">
      <InnerImageZoom
        src={imageUrl}
        zoomSrc={imageUrl}
        zoomType="hover"
        className="max-w-full h-auto object-cover cursor-pointer rounded-lg shadow-md"
        style={{ maxHeight: "90vh", width: "auto" }} // Adjusted styles for increased size
        onError={(e) => {
          console.log("Failed to load image:", imageUrl);
          e.target.src = `/images/default.png`; // Fallback image
        }}
      />
    </div>
  );
};

export default AddNewFormationEntrySlideOver;
