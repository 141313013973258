import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "tailwindcss/tailwind.css";
import { useUser } from "../context/UserContext"; // Adjust the path as needed

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const { user, handleLogin } = useUser();

  useEffect(() => {
    if (user) {
      navigate("/dashboard");
    }
  }, [user, navigate]);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await handleLogin(email, password);
      navigate("/dashboard");
    } catch (error) {
      console.error("Login failed:", error.message);
      alert("Login failed: " + error.message);
    }
  };

  return (
    <div
      className="min-h-screen bg-cover bg-center"
      style={{ backgroundImage: "url('/assets/images/bg.jpg')" }}
    >
      <header className="fixed w-full z-10">
        <div className="container mx-auto px-4">
          <div className="flex justify-between items-center py-4">
            <a className="navbar-brand logo" href="/">
              <img
                src="/assets/images/logo.png"
                alt="data drill"
                className="w-60"
              />
            </a>
            <div className="flex items-center space-x-4">
              <button className="btn-demo bg-red-600 text-white py-2 px-4 rounded hover:bg-red-400 flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="size-6"
                >
                  <path
                    fillRule="evenodd"
                    d="M2.25 5.25a3 3 0 0 1 3-3h13.5a3 3 0 0 1 3 3V15a3 3 0 0 1-3 3h-3v.257c0 .597.237 1.17.659 1.591l.621.622a.75.75 0 0 1-.53 1.28h-9a.75.75 0 0 1-.53-1.28l.621-.622a2.25 2.25 0 0 0 .659-1.59V18h-3a3 3 0 0 1-3-3V5.25Zm1.5 0v7.5a1.5 1.5 0 0 0 1.5 1.5h13.5a1.5 1.5 0 0 0 1.5-1.5v-7.5a1.5 1.5 0 0 0-1.5-1.5H5.25a1.5 1.5 0 0 0-1.5 1.5Z"
                    clipRule="evenodd"
                  />
                </svg>
                Request Demo Access
              </button>
              <button className="btn-contct bg-green-600 text-white py-2 px-4 rounded hover:bg-green-400 flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="size-4 mr-2"
                >
                  <path
                    fillRule="evenodd"
                    d="M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z"
                    clipRule="evenodd"
                  />
                </svg>
                Contact Us
              </button>
            </div>
          </div>
        </div>
      </header>
      <div className="flex justify-end items-center min-h-screen">
        <div className="loginSection bg-blue-800 bg-opacity-80 p-16 w-2/5 fixed right-0 top-0 h-full flex justify-center items-center">
          <div className="loginBox w-full">
            <form onSubmit={handleSubmit}>
              <div className="form-outline mb-6">
                <input
                  type="email"
                  id="typeEmail"
                  className="form-control w-full p-2 rounded bg-gray-200"
                  value={email}
                  onChange={handleEmailChange}
                />
                <label className="form-label text-white" htmlFor="typeText">
                  Email
                </label>
              </div>
              <div className="form-outline mb-6 relative">
                <input
                  type="password"
                  id="typePassword"
                  className="form-control w-full p-2 rounded bg-gray-200"
                  value={password}
                  onChange={handlePasswordChange}
                />
                <label
                  className="form-label text-white"
                  htmlFor="typePassword"
                >
                  Password
                </label>
                <i className="far fa-eye absolute right-2 top-2 cursor-pointer"></i>
              </div>
              <div className="flex justify-between items-center mb-6">
                <button
                  type="submit"
                  className="btn-submit bg-yellow-500 text-gray-900 py-2 px-4 rounded hover:bg-green-600"
                >
                  Login
                </button>
                <a href="#!" className="text-white">
                  Forgot password?
                </a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
