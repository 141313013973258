// src/index.js
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { UserProvider } from './context/UserContext';
import reportWebVitals from "./reportWebVitals";
import { DataProvider } from "./context/DataContext";
import { LocationProvider } from "./context/LocationContext";
import { ImageViewerProvider } from "./context/ImageViewerContext";
import { FormationDataProvider } from "./context/FormationDataContext";
import { ComplicationDataProvider } from "./context/ComplicationDataContext";
import { TrajectoryDataProvider } from "./context/TrajectoryDataContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <UserProvider>
      <LocationProvider>
        <DataProvider>    
            <ImageViewerProvider>
              <FormationDataProvider>
                <ComplicationDataProvider>
                  <TrajectoryDataProvider>
                    <App />
                  </TrajectoryDataProvider>
                </ComplicationDataProvider>
              </FormationDataProvider>
            </ImageViewerProvider>
        </DataProvider>
      </LocationProvider>
    </UserProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
